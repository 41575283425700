import React from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import MetadataService from '../../../common/services/MetadataService';
import ProgramRegistrationFilters from './ProgramRegistrationFilters';

const styles = (theme) => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  }
});

class ProgramRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      filters: { user_active_eq: '1' }
    };
  }

  handleApplyFilters = (filters) => {
    this.setState({ filters: filters });
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order: order, orderBy: orderBy });
  };

  handleExport = () => {
    var sortingParams = {
      direction: this.state.order,
      field: this.state.orderBy
    };
    var searchRequest = this.api.searchUrl(
      this.state.filters,
      null,
      null,
      sortingParams,
      null
    );

    var apiUrl =
      '/api/v1/reports/registrations.csv' + (searchRequest !== '' ? '?' : '');
    var csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  render() {
    const { classes, passportPeriod, institute } = this.props;
    const { filters } = this.state;

    const fields = [
      'user_given_name',
      'user_family_name',
      institute.custom_user_field_1_enabled ? 'custom_1' : null,
      institute.custom_user_field_2_enabled ? 'custom_2' : null,
      'program_name',
      'program_type',
      'domain_display_name',
      'program_start_date',
      'program_end_date',
      'workshops_count',
      'workshops_attended_count',
      'program_points',
      'hurdle_complete',
      'completed',
      'status'
    ].filter(Boolean);

    return (
      <Grid container className={classes.registrationReport} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <ProgramRegistrationFilters
              handleApplyFilters={this.handleApplyFilters}
              passportPeriod={passportPeriod}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">Program Registrations</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>

            <AdminTable
              showTablePagination
              fields={fields}
              filters={filters}
              handleApplySorting={this.handleApplySorting}
              metadata={this.meta.getWithInstituteContext('registrations', institute)}
              resource="reports/registrations"
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(ProgramRegistration, styles, { withTheme: true });
