import React from 'react';
import Checkbox from '@mui/material/Checkbox';

class ValueBoolean extends React.Component {

  handleChange = (event) => {
    this.props.onCellEdit(event.target.checked);
  };

  render() {
    return (
      <Checkbox
        checked={this.props.value}
        onChange={this.handleChange}
        {...this.props.metadata?.componentProps}
      />
    );
  }

}

export default ValueBoolean;
