import React from 'react';
import { withStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AdminTable from '@common/components/table/AdminTable';
import ApiService from '@common/services/ApiService';
import ConfirmationDialog from '@common/components/ConfirmationDialog';
import { attendanceStyles } from './style';
import { AttendanceCell } from './AttendanceCell';
import { AttendanceCheckboxCell } from './AttendanceCheckboxCell';
import { AttendanceProgramIncompleteModal } from './AttendanceProgramIncompleteModal';
import { AttendanceProgramCompleteModal } from './AttendanceProgramCompleteModal';
import { StrikethroughCell } from './StrikethroughCell';

const RemoveRegistrationCell = ({ row }) => (
  <IconButton disabled={row.completed} size="large">
    <CloseIcon />
  </IconButton>
);

class Attendance extends React.Component {
  api = new ApiService();

  state = {
    isIncompleteModalOpened: false,
    isSubmittingIncompleteModal: false,
    isCompleteModalOpened: false,
    isSubmittingCompleteModal: false,
    alertOpen: false,
    registrationId: null
  };

  get metadata() {
    const { workshops, classes } = this.props;

    const metadata = workshops.reduce(
      (acc, workshop) => ({
        ...acc,
        [`workshop_${workshop.id}`]: {
          component: AttendanceCell,
          componentProps: { workshopId: workshop.id },
          label: `Workshop ${workshop.number}`,
          tableCellProps: {
            classes: {
              root: classes.attendanceCheckbox
            },
            padding: 'checkbox'
          },
          type: 'component'
        }
      }),
      {}
    );

    return {
      ...metadata,
      user_family_name: {
        component: StrikethroughCell,
        label: 'Family Name',
        componentProps: {
          attribute: 'user_family_name',
          classes: {}
        },
        type: 'component'
      },
      user_given_name: {
        component: StrikethroughCell,
        label: 'Given Name',
        componentProps: {
          attribute: 'user_given_name',
          classes: {}
        },
        type: 'component'
      },
      user_unique_identifier: {
        component: StrikethroughCell,
        label: 'Employee No.',
        componentProps: {
          attribute: 'user_unique_identifier',
          classes: {}
        },
        type: 'component'
      },
      user_department: {
        component: StrikethroughCell,
        label: 'Department',
        componentProps: {
          attribute: 'user_department',
          classes: {}
        },
        type: 'component'
      },
      status: {
        component: StrikethroughCell,
        label: 'Status',
        componentProps: {
          attribute: 'status',
          classes: {}
        },
        type: 'component'
      },
      completed: {
        component: AttendanceCheckboxCell,
        label: 'Completed',
        componentProps: {
          attribute: 'completed'
        },
        tableCellProps: {
          classes: {
            root: classes.completedCheckbox
          },
          padding: 'none'
        },
        type: 'component'
      },
      hurdle: {
        component: AttendanceCheckboxCell,
        label: 'Hurdle',
        componentProps: {
          attribute: 'hurdle'
        },
        tableCellProps: {
          classes: {
            root: classes.attendanceCheckbox
          },
          padding: 'checkbox'
        },
        type: 'component'
      },
      remove: {
        component: RemoveRegistrationCell,
        label: ' ',
        tableCellProps: { padding: 'none' },
        type: 'component'
      }
    };
  }

  get workshopCellEditHandlers() {
    const { workshops } = this.props;

    return workshops.reduce(
      (acc, workshop) => ({
        ...acc,
        [`workshop_${workshop.id}`]: (id, value, row, done) => {
          this.handleWorkshop(id, value.workshopId, value.value, row, done);
        }
      }),
      {}
    );
  }

  handleCloseIncompleteModal = () => {
    this.setState({ isIncompleteModalOpened: false });
  };

  handleCloseCompleteModal = () => {
    this.setState({ isCompleteModalOpened: false });
  };

  handleCompleteCellEdit = (id, value, row, done) => {
    if (value) {
      this.setState({ isCompleteModalOpened: true, registrationId: id });

      return;
    }

    this.setState({ isIncompleteModalOpened: true, registrationId: id });
  };

  handleConfirmEducatorIncomplete = async () => {
    this.setState({ isSubmittingIncompleteModal: true });

    try {
      await this.updateRegistrationCompleted(this.state.registrationId, false);
    } catch (error) {
      //TODO: we will need to implement some way to handle the error here, maybe using a snackbar
    } finally {
      this.setState({
        isSubmittingIncompleteModal: false,
        isIncompleteModalOpened: false
      });
    }

    this.props.onRefreshRequest();
  };

  handleConfirmEducatorComplete = async notify_user => {
    this.setState({ isSubmittingCompleteModal: true });

    try {
      await this.updateRegistrationCompleted(
        this.state.registrationId,
        true,
        notify_user
      );
    } catch (error) {
      //TODO: we will need to implement some way to handle the error here, maybe using a snackbar
    } finally {
      this.setState({
        isSubmittingCompleteModal: false,
        isCompleteModalOpened: false
      });
    }

    this.props.onRefreshRequest();
  };

  handleHurdleCellEdit = (id, value, row, done) => {
    const url =
      '/api/v1/programs/' + this.props.program.id + '/registrations/' + id;

    this.api.put(url, { registration: { hurdle: value } }).then(() => {
      done();
    });
  };

  handleRemove = (row, reload) => {
    if (row.completed) {
      return;
    } else {
      this.setState({
        alertOpen: true,
        registrationId: row.id,
        reload: reload
      });
    }
  };

  removeRegistration = () => {
    const url = 'programs/' + this.props.program.id + '/registrations';
    this.api
      .delete(url, this.state.registrationId)
      .then(() => {
        this.state.reload(true);
        this.setState({ reload: null });
      })
      .catch(ex => {
        console.error(ex);
      });
  };

  handleWorkshop = (registrationId, workshopId, value, row, done) => {
    const url = '/api/v1/attendances/mark';
    var apiCall = this.api.post(url, {
      attendance: {
        registration_id: registrationId,
        workshop_id: workshopId,
        present: value
      }
    });
    apiCall.then(() => {
      done();
    });
  };

  updateRegistrationCompleted = (id, value, notify_user) => {
    const url = `/api/v1/programs/${this.props.program.id}/registrations/${id}`;

    return this.api.put(url, {
      registration: { completed: value },
      notify_user
    });
  };

  render() {
    const {
      classes,
      program: { program_type: programType }
    } = this.props;

    const {
      isIncompleteModalOpened,
      isSubmittingIncompleteModal,
      isSubmittingCompleteModal,
      isCompleteModalOpened
    } = this.state;

    if (this.props.program === null) {
      return null;
    }

    const workshops = this.props.workshops.map(
      workshop => 'workshop_' + workshop.id
    );

    var fields = [
      'user_family_name',
      'user_given_name',
      'user_unique_identifier',
      'user_department',
      'status'
    ].concat(workshops);
    fields = fields.concat(programType === 'elective' ? [] : ['hurdle']);
    fields = fields.concat(['completed', 'remove']);

    return (
      <Paper>
        <Typography className={classes.attendanceTitle} variant="h6">
          Registered Educators
        </Typography>
        <AdminTable
          noPaginationQuery
          tableBottomPadding
          fields={fields}
          metadata={this.metadata}
          onCellClicked={{ remove: this.handleRemove }}
          onCellEdit={{
            hurdle: this.handleHurdleCellEdit,
            completed: this.handleCompleteCellEdit,
            ...this.workshopCellEditHandlers
          }}
          onDataLoaded={this.props.onDataLoaded}
          refreshTick={this.props.refreshTick}
          resource={`programs/${this.props.program.id}/registrations`}
          orderBy={this.props.defaultOrderBy}
          handleApplySorting={this.props.handleApplySorting}
        />
        <AttendanceProgramIncompleteModal
          isLoading={isSubmittingIncompleteModal}
          isOpened={isIncompleteModalOpened}
          onClose={this.handleCloseIncompleteModal}
          onSubmit={this.handleConfirmEducatorIncomplete}
        />
        <AttendanceProgramCompleteModal
          isLoading={isSubmittingCompleteModal}
          isOpened={isCompleteModalOpened}
          onClose={this.handleCloseCompleteModal}
          onSubmit={this.handleConfirmEducatorComplete}
        />
        <ConfirmationDialog
          message="Delete registration?"
          onOk={this.handleAlertOk}
          onCancel={this.handleAlertCancel}
          open={this.state.alertOpen}
          title=""
        />
      </Paper>
    );
  }

  handleAlertOk = () => {
    this.setState({ alertOpen: false });
    this.removeRegistration();
  };

  handleAlertCancel = () => {
    this.setState({ alertOpen: false });
  };
}

const StyledAttendance = withStyles(Attendance, attendanceStyles, { withTheme: true });

export { StyledAttendance as Attendance };
