import React from 'react';
import Checkbox from '@mui/material/Checkbox';

export class AttendanceCell extends React.Component {
  handleChange = event => {
    const { target } = event;
    const { onCellEdit, workshopId } = this.props;

    onCellEdit({
      value: target.checked,
      workshopId
    });
  };

  render() {
    const { attendances = [] } = this.props.row;
    const attendance = attendances.find(
      attendance => this.props.workshopId == attendance.workshop_id
    );
    const value = attendance && attendance.present;

    return <Checkbox checked={value} onChange={this.handleChange} />;
  }
}
